import React, { useState } from "react";
import styled from "styled-components";
import { StyledSidebar, SidebarList, SidebarItem, ToggleButton } from "./style";

const sections = [
  // {
  //   name: "Marketing",
  //   // children: [
  //   //   { name: "Digital Marketing" },
  //   //   { name: "Business Strategy" },
  //   //   {
  //   //     name: "Finance",
  //   //     // children: [{ name: "Budget" }, { name: "Corporate Finance" }],
  //   //   },
  //   // ],
  // },
  {
    name: "Technology",
    // children: [
    //   {
    //     name: "Frontend",
    //     // children: [
    //     //   { name: "HTML" },
    //     //   { name: "CSS" },
    //     //   { name: "React" },
    //     //   { name: "Angular" },
    //     //   { name: "Vue" },
    //     //   { name: "Remix" },
    //     // ],
    //   },
    //   {
    //     name: "Backend",
    //     children: [{ name: "Node Js" }, { name: "Python" }, { name: "Java" }],
    //   },
    //   {
    //     name: "Infrstructure",
    //     children: [
    //       { name: "AWS" },
    //       { name: "Google Cloud" },
    //       { name: "Azure Cloud" },
    //     ],
    //   },
    // ],
  },
  // {
  //   name: "Health",
  //   // children: [
  //   //   { name: "Item 1" },
  //   //   { name: "Item 2" },
  //   //   {
  //   //     name: "Finance",
  //   //     children: [{ name: "SubItem 1" }, { name: "SubItem 2" }],
  //   //   },
  //   // ],
  // },
  // {
  //   name: "Legal",
  //   // children: [
  //   //   { name: "Item 1" },
  //   //   { name: "Item 2" },
  //   //   {
  //   //     name: "Finance",
  //   //     children: [{ name: "SubItem 1" }, { name: "SubItem 2" }],
  //   //   },
  //   // ],
  // },
  // {
  //   name: "LifeSciences",
  //   // children: [
  //   //   { name: "Item 1" },
  //   //   { name: "Item 2" },
  //   //   {
  //   //     name: "Finance",
  //   //     children: [{ name: "SubItem 1" }, { name: "SubItem 2" }],
  //   //   },
  //   // ],
  // },
];

const NestedItem = ({ item, level, toggle, active, isOpen }) => {
  const hasChildren = item.children && item.children.length > 0;

  return (
    <SidebarItem
      key={item.name}
      onClick={() => hasChildren && toggle(item.name)}
    >
      <b> {isOpen ? item.name : item.name.charAt(0).toUpperCase()}</b>
      {active.includes(
        isOpen ? item.name : item.name.charAt(0).toUpperCase()
      ) &&
        hasChildren && (
          <SidebarList>
            {item.children.map((child) => (
              <NestedItem
                key={child.name}
                item={child}
                level={level + 1}
                toggle={toggle}
                active={active}
              />
            ))}
          </SidebarList>
        )}
    </SidebarItem>
  );
};

const Sidebar = ({ isOpen, setIsOpen }) => {
  const [activeSections, setActiveSections] = useState([]);

  const toggleSection = (section) => {
    setActiveSections((prev) =>
      prev.includes(section)
        ? prev.filter((s) => s !== section)
        : [...prev, section]
    );
  };

  return (
    <StyledSidebar isOpen={isOpen}>
      <ToggleButton onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? "<" : ">"}{" "}
      </ToggleButton>
      <SidebarList>
        {sections.map((section) => (
          <NestedItem
            key={section.name}
            item={section}
            level={1}
            toggle={toggleSection}
            active={activeSections}
            isOpen={isOpen}
          />
        ))}
      </SidebarList>
    </StyledSidebar>
  );
};

export default Sidebar;
