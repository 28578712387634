import React from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";
import {
  FooterContainer,
  FooterLogoImage,
  FooterGrid,
  FooterItem,
  LinkedStackText,
  QuickLink,
  UsefulLink,
} from "./footerVersionZeroOneStyle";
import LogoImage from "../../../assets/logoMain.png";


const FooterVersionZeroOne = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme?.breakpoints?.down('md'));

  const scrollToSection = (e) => {
    e.preventDefault();
    const targetId = e?.currentTarget?.getAttribute("data-to");
    const targetElement = document?.querySelector(targetId);

    if (targetElement) {
      targetElement?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <FooterContainer>
      <Grid container spacing={isSmallScreen ? 2 : 3}>
        <Grid item xs={12} sm={12} >
          <FooterLogoImage src={LogoImage} width="150px" height="80px" />
        </Grid>
        <Grid item xs={12}>
          <FooterGrid>
            <FooterItem>
              <LinkedStackText >
                As India's premier web development company, Linked Stack Solutions excels in delivering top-tier services across web development, AI development, unit testing, cloud computing, project and team management, and DevOps. We specialize in creating unique, high-quality websites and animated motion graphic videos, all at competitive prices.
              </LinkedStackText>
            </FooterItem>
            <FooterItem>
              <Typography variant="body2" sx={{ fontSize: "20px", margin: "5px" }}>
                Quick Links
              </Typography>
              <QuickLink href="#services" data-to="#services" onClick={scrollToSection}>
                Home
              </QuickLink>
              <QuickLink href="#services" data-to="#services" onClick={scrollToSection}>
                Service
              </QuickLink>
              <QuickLink href="#contactus" data-to="#contactus" onClick={scrollToSection}>
                Contact Us
              </QuickLink>
            </FooterItem>
            <FooterItem>
              <Typography variant="body2" sx={{ fontSize: "20px", margin: "5px" }}>
                Useful Links
              </Typography>
              <UsefulLink href="#">Refund & Cancellation</UsefulLink>
              <UsefulLink href="#">Privacy Policy</UsefulLink>
              <UsefulLink href="#">Terms of Service</UsefulLink>
            </FooterItem>
          </FooterGrid>
        </Grid>
      </Grid>
    </FooterContainer>
  );
};

export default FooterVersionZeroOne;
