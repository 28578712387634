import React from "react";
import "./index.css";

const FooterVersionZeroTwo = () => {
  return (
    <div className="footer-container">
      <div className="content">
        <p>© 2024 LSS. All rights reserved.</p>
        <p>
          <b style={{ fontWeight: "bold", fontSize: "14px" }}>Contact Us: </b>
          customersupport@linkedstacksolutions.com
        </p>
      </div>
    </div>
  );
};

export default FooterVersionZeroTwo;
