import React from "react";
import "./index.css";

const PropertyOptions = () => {
  return (
    <div className="container" id="lease">
      <div className="home-card">
        <div className="card-header">Choose Your Option</div>
        <div className="card-body">
          <button
            className="btn"
            onClick={() =>
              window.open("https://forms.gle/rFjNqp1bik3deX2P6", "_blank")
            }
          >
            Property for Rent
          </button>
          <button
            className="btn"
            onClick={() => window.open("https://forms.gle/tdB68855aMcoVAwL7")}
          >
            Post your property
          </button>
        </div>
      </div>
    </div>
  );
};

export default PropertyOptions;
