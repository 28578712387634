import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: "290px",
  },
}));

export const StyledListItem = styled(({ ...other }) => (
  <ListItem button {...other} />
))(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  padding: theme.spacing(1),
  fontWeight: 900,
  display: "inline-block",
  letterSpacing: "0.2px",
  textTransform: "capitalize",
  fontSize: "10px",
  color: "black",
}));
