import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";

import {
  StyledAppBar,
  StyledToolbar,
  Logo,
  MenuButton,
  MenuIconButton,
  DropdownMenuWrapper,
  LoginButton,
  MenuContainer,
} from "./headerVersionOneStyle.js";
import MainLogo from "../../../assets/logoMain.png";
import Sidebar from "../../responsiveDrawer/sidebar.js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DropdownMenu from "../../dropDownMenu/dropDownMenu.js";
import { useNavigate } from "react-router-dom";

const HeaderVersionOne = () => {
  const navigate =useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const toggleDropdown = () => {
    setDropDownOpen((prevOpen) => !prevOpen);
  };

  const handleClickAway = () => {
    setDropDownOpen(false);
  };

  useEffect(() => {
    console.log("Dropdown open:", dropDownOpen);
  }, [dropDownOpen]);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNavigate = () =>{
    navigate('/login')

  }
  return (
    <div>
      <StyledAppBar position="static">
        <StyledToolbar>
          <Logo src={MainLogo} alt="Linked Stack Solution" />
          {isMobile ? (
            <MenuIconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
             >
              <MenuIcon />
            </MenuIconButton>
          ) : (
            <MenuContainer>
              <MenuButton color="inherit">Company</MenuButton>

              <MenuButton
                color="inherit"
                onClick={() => scrollToSection("services")}
              >
                Services
              </MenuButton>

              <DropdownMenuWrapper>
                <MenuButton onClick={toggleDropdown} color="inherit">
                  Website Promotion
                  <KeyboardArrowDownIcon />
                </MenuButton>
                <DropdownMenu open={dropDownOpen} onClose={handleClickAway} />
              </DropdownMenuWrapper>
              <MenuButton
                color="inherit"
                onClick={() => scrollToSection("upskill")}
              >
                Upskill
              </MenuButton>
              <MenuButton color="inherit"
                onClick={() => scrollToSection("team")}
              >Expert</MenuButton>

              <MenuButton
                color="inherit"
                onClick={() => scrollToSection("contactus")}
              >
                ContactUs
              </MenuButton>
              <MenuButton
                color="inherit"
                onClick={() => scrollToSection("team")}
              >
                Blogs
              </MenuButton>

              { false &&<LoginButton
                color="inherit"
               variant="contained"
               onClick={handleNavigate}
              >
                Login
              </LoginButton>}
            </MenuContainer>
          )}
        </StyledToolbar>
      </StyledAppBar>
      <Sidebar open={drawerOpen} onClose={toggleDrawer(false)} />
    </div>
  );
};

export default HeaderVersionOne;
