import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import color from "../../../src/comman/color";

export const StyledPaper = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: 56,
  right: 0,
  margin: "2%",
  zIndex: 1,
  minWidth: 500,
  padding: "14px 20px 14px 10px",
}));

export const HeadingTypography = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 500,
  textAlign: "justify",
  textTransform: "uppercase",
  marginBottom: theme.spacing(1),
}));


export const SubheadingTypography = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 500,
  textAlign: "justify",

  color: theme.palette.text.secondary,
}));
export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&:hover": {
    boxShadow: "0 4px 2px 0px",
    borderBottom: "1px solid #087990",
    background: color.WHITE,
    borderRadius: "5px solid",
  },
}));

export const IconBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: "10px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
export const StyledBox = styled(Box)(({ theme, bgcolor }) => ({
  backgroundColor: bgcolor,
  padding: '10px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));