import React from "react";
import "./index.css";

import ContactUsIcon from "../../assets/ImageTwoTwo.png";
import FacebookIcon from "../../assets/facebook.png";
import WhatsAppIcon from "../../assets/whatsapp.png";
const SocialMediaFloat = () => {
  const phoneNumber = "7015309925";
  const message = encodeURIComponent("Hi, I need support");
  const waLink = `https://wa.me/${phoneNumber}?text=${message}`;
  return (
    <div className="social-float">
      <a
        href={waLink}
        target="_blank"
        rel="noopener noreferrer"
        className="icon whatsapp"
      >
        <img src={WhatsAppIcon} width={"25px"} height={"25px"} />
        <i className="fab fa-whatsapp"></i>
      </a>
      <a
        href="https://forms.gle/puahtUijKfGaCZvX8"
        target="_blank"
        rel="noopener noreferrer"
        className="icon google-form"
      >
        <img src={ContactUsIcon} width={"25px"} height={"25px"} />
        <i className="fas fa-external-link-alt"></i>
      </a>
      <a
        href="https://www.facebook.com/people/Linked-Stack-Solutions/61555396271331/?mibextid=2JQ9oc"
        target="_blank"
        rel="noopener noreferrer"
        className="icon facebook"
      >
        <img src={FacebookIcon} width={"25px"} height={"25px"} />
      </a>
      {/* <a
        href="https://www.instagram.com/your-profile"
        target="_blank"
        rel="noopener noreferrer"
        className="icon instagram"
      >
     
        <i className="fab fa-instagram"></i>
      </a> */}
    </div>
  );
};

export default SocialMediaFloat;
