import React, { useState } from "react";
import styled from "styled-components";

import Sidebar from "./components/sidebar";
import ReactBlogs from "./data/tech/frontend/react";

import {
  MainContainer,
  SidebarConatiner,
  MainContent,
  Container,
  Article,
  Heading,
  Image,
  Checkbox,
  Button,
  Paragraph,
  Meta,
  SubHeading,
} from "./style";

const ArticleComponent = () => {
  const [articles, setArticles] = useState(ReactBlogs);
  const [showSelected, setShowSelected] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (id) => {
    const updatedArticles = articles.map((article) =>
      article.id === id ? { ...article, selected: !article.selected } : article
    );
    setArticles(updatedArticles);
  };

  const handleShowSelected = () => {
    setShowSelected(!showSelected);
  };

  return (
    <MainContainer>
      <SidebarConatiner>
        <Sidebar isOpen={isOpen} setIsOpen={setIsOpen} />
      </SidebarConatiner>
      <MainContent>
        <Container>
          {/* <Button onClick={handleShowSelected}>
            {showSelected ? "Show All Articles" : "Show Selected Articles"}
          </Button> */}
          {articles
            .filter((article) => !showSelected || article.selected)
            .map((article) => (
              <Article key={article.id}>
                {/* <Checkbox
                  type="checkbox"
                  checked={article.selected}
                  onChange={() => handleSelect(article.id)}
                /> */}
                <Heading>{article.heading}</Heading>
                <Image src={article.image} alt={article.heading} />
                <SubHeading>{article.subHeading}</SubHeading>
                <Meta>
                  By {article.owner}, Published by {article.publishedBy}
                </Meta>
                {article.paragraph.map((para, idx) => (
                  <Paragraph key={idx}>{para}</Paragraph>
                ))}
              </Article>
            ))}
        </Container>
      </MainContent>
    </MainContainer>
  );
};

export default ArticleComponent;
