import styled from "styled-components";
export const MainContainer = styled.div`
  display: flex;
  background: white;
  max-height: 100vh;
  overflow-y: auto;
`;

export const SidebarConatiner = styled.div`
  width: 20%;
  height: 100vh; // Set the height to 100% of the viewport height
`;

export const MainContent = styled.div`
  flex-grow: 1;
  max-height: 100vh; // Set the maximum height to match the sidebar
  overflow-y: auto; // Enable scrolling within MainContent
`;
export const Container = styled.div`
  // display: flex;
  // flex-direction: column;
  align-items: center;
  background-color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
`;

export const Article = styled.div`
  margin: 20px;
  padding: 20px;
  max-width: 800px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #f8f8f8;
`;

export const Heading = styled.h2`
  font-size: 2em;
  margin-bottom: 0.5em;
`;

export const Image = styled.img`
  max-width: 100%;
  height: 200px;
  border-radius: 10px;
`;

export const SubHeading = styled.h4`
  font-size: 1.2em;
  color: #333;
  margin-bottom: 0.5em;
`;

export const Meta = styled.p`
  font-style: italic;
  color: #666;
  margin-bottom: 1em;
`;

export const Paragraph = styled.p`
  text-align: justify;
  line-height: 1.6;
`;

export const Checkbox = styled.input`
  margin-right: 10px;
`;

export const Button = styled.button`
  padding: 10px 20px;
  margin: 20px 0;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;
