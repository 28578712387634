import React from "react";
import "./index.css"; // Import the CSS file for styling
import Gary from "../../assets/gary.png";
import Ruchi from "../../assets/ruchi.png";
import Adarsh from "../../assets/adarsh.png";
// import BladeSound from "../../assets/blade.mp3"; // Path to your blade sound file

const teamMembers = [
  {
    name: "Gavyn Cadwell",
    professionalSummary:
      "With over five years of extensive experience, Gavyn Cadwell is a highly proficient React developer. His comprehensive expertise in various React-based projects showcases a deep understanding and adept skill in this technology.",
    img: Gary, // Ensure to import the image at the top of your file
  },
  {
    name: "Neha",
    professionalSummary:
      "Neha is a seasoned Digital Marketing specialist with over 10+ years of experience. Known for her strategic approach in online marketing, she excels in SEO, social media marketing, and content creation, significantly enhancing brand presence and engagement.",
    img: Ruchi,
  },
  {
    name: "Ruchi Gaur",
    professionalSummary:
      "Ruchi Gaur, a dedicated React developer, brings two years of intensive experience to the team. Her career, though brief, has been marked by substantial hands-on engagement and contributions to React-centric projects.",
    img: Ruchi, // Ensure to import the image at the top of your file
  },
  {
    name: "Adarsh",
    professionalSummary:
      "Adarsh stands out as a dynamic professional with over two years of experience in Business Development. His career is distinguished by a robust track record of strategic growth initiatives and relationship building in diverse business environments.",
    img: Adarsh, // Ensure to import the image at the top of your file
  },
];

const ProfessionalCard = () => {
  // Function to play sound
  const playSound = () => {
    // const sound = document.getElementById("blade-sound");
    // sound.play();
  };

  return (
    <div className="experts">
      <h1> Our Experts</h1>
      <div className="card-container" id="team">
        {teamMembers.map((professional, index) => (
          <div key={index} className="card" onMouseEnter={playSound}>
            <img
              src={professional.img}
              alt={professional.name}
              className="avatar"
            />
            <div className="info">
              <h2 className="name">{professional.name}</h2>
              <p>{professional.professionalSummary}</p>
            </div>
          </div>
        ))}
      </div>
      {/* <audio id="blade-sound" src={BladeSound}></audio> */}
    </div>
  );
};

export default ProfessionalCard;
