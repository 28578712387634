import React from "react";
import Facebook from "../../../../src/assets/facebook.png";
import Query from "../../../../src/assets/query.png";
import Whatsapp from "../../../../src/assets/whatsapp.png";
import "./index.css";

const SocialMediaFloat = () => {
  return (
    <div className="social-float">
      <a
        href="https://wa.me/7015309925"
        target="_blank"
        rel="noopener noreferrer"
        className="icon whatsapp"
      >
        <img src={Whatsapp} height={"40px"} width={"40px"}></img>
      </a>
      {/* <a
        href="https://your-google-form-link"
        target="_blank"
        rel="noopener noreferrer"
        className="icon google-form"
      >
        <img src={Query} height={"20px"} width={"20px"}></img>
      </a> */}
      {/* <a
        href="https://www.facebook.com/your-profile"
        target="_blank"
        rel="noopener noreferrer"
        className="icon facebook"
      >
        <img src={Facebook} height={"20px"} width={"20px"}></img>
      </a> */}
      {/* <a
        href="https://www.instagram.com/your-profile"
        target="_blank"
        rel="noopener noreferrer"
        className="icon instagram"
      >
      
        <img src={Facebook} height={"20px"} width={"20px"}></img>
      </a> */}
    </div>
  );
};

export default SocialMediaFloat;
