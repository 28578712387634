import React from "react";
import { Container, Title, Detail, DetailItem } from "./familDetailsStyle"; // Import styled components

const FamilyDetails = ({ family }) => {
  return (
    <Container>
      <Title>Family Details</Title>
      <Detail>
        <DetailItem>
          <strong>Father's Name:</strong> {family.fatherName}
        </DetailItem>
        <DetailItem>
          <strong>Father's Occupation:</strong> {family.fatherOccupation}
        </DetailItem>
        <DetailItem>
          <strong>Mother's Name:</strong> {family.motherName}
        </DetailItem>
        <DetailItem>
          <strong>Mother's Business:</strong> {family.motherBusiness}
        </DetailItem>
        <DetailItem>
          <strong>Elder Brother's Name:</strong> {family.elderBrotherName}
        </DetailItem>
        <DetailItem>
          <strong>Elder Brother's Wife Name:</strong>{" "}
          {family.elderBrotherWifeName}
        </DetailItem>
        <DetailItem>
          <strong>Elder Brother's Occupation:</strong>{" "}
          {family.elderBrotherOccupation}
        </DetailItem>
      </Detail>
    </Container>
  );
};

export default FamilyDetails;
