// RectStyles.js
import styled from "styled-components";

export const Avatar = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
`;

export const Name = styled.div`
  float: left;
  vertical-align: top;
  font-size: 32px;
  font-weight: 700;
  margin-top: 10%;
  color: #333;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: black;
  margin-top: 10px;
`;
