import React from "react";
import { Container, Card, Title, Info } from "./personalInformationStyle";

const RectComponent = ({ details }) => {
  const renderDetailCards = (detail) => {
    const detailKeys = [
      "age",
      "height",
      "Date of Birth",
      "Time of Birth",
      "Place of Birth",
      "horoscope",
      "caste",
    ];
    return detailKeys.map((key) => (
      <Card key={key}>
        <Title>{key.toUpperCase()}</Title>
        <Info>{detail[key]}</Info>
      </Card>
    ));
  };

  return (
    <Container>
      {details.map((detail, index) => (
        <React.Fragment key={index}>{renderDetailCards(detail)}</React.Fragment>
      ))}
    </Container>
  );
};

export default RectComponent;
