import React, { useState } from "react";
import "./index.css";

function Home() {
  // Array of market data
  const marketData = [
    { sector: "Sector 29", rate: "₹8,000/sq.ft", trend: "Upward" },
    { sector: "Sector 48", rate: "₹7,500/sq.ft", trend: "Stable" },
    { sector: "Sector 62", rate: "₹6,800/sq.ft", trend: "Upward" },
    { sector: "Sohna Road", rate: "₹5,600/sq.ft", trend: "Upward" },
    { sector: "Sector 56", rate: "₹7,200/sq.ft", trend: "Stable" },
    { sector: "New Dwarka Phase 1", rate: "₹9,500/sq.ft", trend: "Upward" },
    { sector: "Sector 14", rate: "₹7,000/sq.ft", trend: "Stable" },
    { sector: "Sector 37", rate: "₹6,300/sq.ft", trend: "Upward" },
    { sector: "Sohna Sector 7", rate: "₹4,800/sq.ft", trend: "Stable" },
    { sector: "Sector 82", rate: "₹7,800/sq.ft", trend: "Upward" },
    { sector: "New Dwarka Phase 2", rate: "₹9,200/sq.ft", trend: "Upward" },
    { sector: "Sector 15", rate: "₹7,100/sq.ft", trend: "Stable" },
    { sector: "Sector 45", rate: "₹7,400/sq.ft", trend: "Stable" },
    { sector: "Sohna Sector 11", rate: "₹4,600/sq.ft", trend: "Upward" },
    { sector: "Sector 92", rate: "₹7,900/sq.ft", trend: "Upward" },
    { sector: "New Dwarka Phase 3", rate: "₹9,800/sq.ft", trend: "Upward" },
    { sector: "Sector 18", rate: "₹7,300/sq.ft", trend: "Stable" },
    { sector: "Sector 54", rate: "₹7,600/sq.ft", trend: "Stable" },
    { sector: "Sohna Sector 14", rate: "₹4,900/sq.ft", trend: "Upward" },
    { sector: "Sector 99", rate: "₹8,200/sq.ft", trend: "Upward" },
    // Add more sectors and data as needed
  ];

  // Number of items to display per page
  const itemsPerPage = 5;

  // Initialize state for current page
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the total number of pages
  const totalPages = Math.ceil(marketData.length / itemsPerPage);

  // Function to handle page changes
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the start and end indices for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Filter the data for the current page
  const currentData = marketData.slice(startIndex, endIndex);

  return (
    <div className="home-container">
      <div className="home-content">
        <h1>Elegance Redefined</h1>
        <h2>Your Dream Home in Gurgaon Awaits</h2>
        <p>
          Step into a world where every detail embodies luxury. Find your haven
          in the heart of Gurgaon.
        </p>
      </div>
      <div className="home-image">
        {/* <img src="path-to-your-3d-home-image.jpg" alt="Elegant Home" /> */}
      </div>
      <div className="market-table">
        <table>
          <thead>
            <tr>
              <th>Sector</th>
              <th>Average Rate</th>
              <th>Trend</th>
            </tr>
          </thead>
          <tbody>
            {currentData.map((data, index) => (
              <tr key={index}>
                <td>{data.sector}</td>
                <td>{data.rate}</td>
                <td>{data.trend}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Paginator */}
        <div className="paginator">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>{currentPage}</span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default Home;
