const modules = [
  {
    module: "Module 1",
    topic: "Es6 Primer",
    subTopics: [
      "1.1 JavaScript history",
      "1.2 ES6 and Babel",
      "1.3 Arrow functions and template literals",
      "1.4 Rest and spread operators",
      "1.5 Classes and inheritance",
      "1.6 Static methods and properties",
      "1.7 Iterators and iterables",
      "1.8 Generators",
    ],
  },
  {
    module: "Module 2",
    topic: "Introduction to React",
    subTopics: [
      "2.1 Why React?",
      "2.2 Version history of React",
      "2.3 React 15 vs React 16",
      "2.4 Using create-react-app",
      "2.5 Debugging first react app",
    ],
  },
  {
    module: "Module 3",
    topic: "Templating Using JSX",
    subTopics: [
      "3.1 Working with React.createElement",
      "3.2 Logical operators and expressions",
      "3.3 Specifying attributes and children",
    ],
  },
  {
    module: "Module 4",
    topic: "Components of React",
    subTopics: [
      "4.1 Component architecture and its significance",
      "4.2 Types of components",
    ],
  },
  {
    module: "Module 5",
    topic: "Working with Props and State",
    subTopics: [
      "5.1 State and its significance",
      "5.2 Set and read states",
      "5.3 Passing data to a component through props",
      "5.4 Validation of props with propTypes",
      "5.5 Using default props to supply default values to props",
      "5.6 Rendering lists",
      "5.7 Using the React key prop",
      "5.8 Using the map function for iteration on arrays to generate elements",
    ],
  },
  {
    module: "Module 6",
    topic: "Event Handling in React",
    subTopics: [
      "6.1 React event system and the synthetic event",
      "6.2 Passing arguments to event handlers",
      "6.3 Component lifecycle",
      "6.4 Significance of lifecycle methods and real-time use cases",
    ],
  },
  {
    module: "Module 7",
    topic: "Working with Forms",
    subTopics: [
      "7.1 Controlled and uncontrolled components",
      "7.2 Significance of the default Value prop",
      "7.3 Using the React ref prop to access the DOM element",
    ],
  },
  {
    module: "Module 8",
    topic: "Routing with React Router",
    subTopics: [
      "8.1 Setting up the router",
      "8.2 Understanding routing with the help of single-page applications",
      "8.3 Working with HashRouter and BrowserRouter components",
      "8.4 Configuration of the router, using the route component",
      "8.5 Using switch component for defining routing rules",
      "8.6 Using route params for making routes dynamic",
      "8.7 Working with nested routes",
      "8.8 Navigation to pages, using NavLink and Link components",
      "8.9 Using the redirect component to redirect the route",
      "8.10 Using the prompt component to get user consent for navigation",
      "8.11 Making use of the pathless route for handling failed matches",
    ],
  },
  {
    module: "Module 9",
    topic: "Just Redux and Immutable.js",
    subTopics: [
      "9.1 What is Redux?",
      "9.2 Why Redux?",
      "9.3 Principles of Redux",
      "9.4 Installation and setup process of Redux",
      "9.5 Creation of reducer, actions, and store",
      "9.6 What is Immutable.js?",
      "9.7 Collections in Immutable",
      "9.8 Lists, sets, and maps",
    ],
  },
  {
    module: "Module 10",
    topic: "React Redux",
    subTopics: [
      "10.1 What is React Redux, and why is it used?",
      "10.2 Installation and setting up of React Redux",
      "10.3 Container components vs presentational components",
      "10.4 Understanding the high-order component",
      "10.5 Understanding mapDispatchtToProps and mapStateToProps usage",
    ],
  },
  {
    module: "Module 11",
    topic: "Redux Middleware",
    subTopics: [
      "11.1 Why Redux middleware?",
      "11.2 Choices available in Redux middleware",
      "11.3 Redux saga",
      "11.4 Setting up and installing Redux",
      "11.5 Working with saga helpers",
      "11.6 Promises vs sagas",
    ],
  },
  {
    module: "Module 12",
    topic: "Unit Testing",
    subTopics: [
      "12.1 Significance of unit testing",
      "12.2 Understanding unit testing tools and jargons",
      "12.3 Unit testing react components, using enzyme and jest",
    ],
  },
  {
    module: "Module 13",
    topic: "New Features of React 16",
    subTopics: [
      "13.1 Handling exceptions in components",
      "13.2 Error boundaries",
    ],
  },
  {
    module: "Module 14",
    topic: "Webpack Primer and Isomorphic React",
    subTopics: [
      "14.1 Webpack and its use",
      "14.2 Setting up and installing Webpack",
      "14.3 Working with the configuration file of Webpack",
      "14.4 Working with loaders",
      "14.5 Quick word on lazy loading, code splitting, and tree shaking",
      "14.6 Setting up a hot module replacement",
      "14.7 Server-side rendering (SSR)",
      "14.8 Working with renderToStaticMarkup and renderToString methods",
    ],
  },
  {
    module: "Module 15",
    topic: "React 18 latest features",
    subTopics: [
      "15.1 Upgrade to react 18",
      "15.2 Concept of Concurrent React",
      "15.3 Features - Automatic Batching, Transitions, Suspense on the server",
      "15.4 Hooks - useId, useTransition, useDeferredValue, useSyncExternalStore, useInsertionEffect",
      "15.5 Updates - Strict mode",
      "15.6 Deprecated/discouraged - ReactDOM.render, renderToString",
      "15.7 APIs - createRoot, hydrateRoot, renderToPipeableStream, renderToReadableStream",
    ],
  },
];
export default modules;
