import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "../src/components/header";
import Company from "../src/components/company";
import Contact from "../src/components/contactus";
import Teams from "../src/components/teams";
import TechnologyList from "../src/components/learning";
import Calendly from "../src/components/scheduleCall";
import Main from "../src/components/main";
import SocialIcons from "../src/components/social";
import { useEffect } from "react";
import RealEstate from "../src/components/homeWeb";
import Portfolio from "../src/components/portfolios";
import ArticleComponent from "../src/components/blogs";
import GarvitAnandProfile from "../src/components/matrimonial";
import HomePage from "../src/components/devaCode/MarutiFirstPage";
import Footer from "../src/components/footer";
import HeaderVersionOne from "./components/header/headerVersionOne/headerVersionOne";
import LoginForm from "./components/LoginForm/loginForm";
function App() {


  return (
    <Router>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <Main />
                <Company />
                <Contact />
                <TechnologyList />
                <Portfolio />
                <SocialIcons />
                <Teams />
                <Calendly />
                <Footer />
              </>
            }
          />
          <Route exact={true} path="/homeService" element={<RealEstate />} />
          <Route exact={true} path="/blogs" element={<ArticleComponent />} />
          <Route
            exact={true}
            path="/GarvitAnandMProfile"
            element={<GarvitAnandProfile />}
          />
          <Route exact={true} path="/deva" element={<HomePage />} />
          <Route exact={true} path="/login" element={<LoginForm />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
