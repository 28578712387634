import React, { useState, useEffect } from "react";
import { ImageContainer } from "./imagesStyle"; // Importing styled component
import cardBackgroundImage from "../../../assets/imageSevenThree.png";
import GARY_01 from "../../../assets/gary.png";

const imageUrls = [GARY_01];

const RotatingImageDisplay = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex(
        (currentImageIndex) => (currentImageIndex + 1) % imageUrls.length
      );
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, []);

  return (
    <ImageContainer imageUrl={imageUrls[currentImageIndex]}>
      {/* You can add additional content over the image here if needed */}
    </ImageContainer>
  );
};

export default RotatingImageDisplay;
