import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Icon, Divider, List } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  StyledDrawer,
  StyledListItem,
  StyledListItemText,
} from "../responsiveDrawer/sidebarStyle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AnotherComponent from "../../components/anotherComponent/anotherComponent";

const Sidebar = ({ open, onClose }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const list = () => (
    <div role="presentation">
      <List>
        <IconButton onClick={onClose}>
          <Icon>
            <CloseIcon />
          </Icon>
        </IconButton>
        <StyledListItem>
          <StyledListItemText primary="Company" />
        </StyledListItem>
        <Divider />
        <StyledListItem>
          <StyledListItemText primary="Service" />
        </StyledListItem>
        <Divider />
        <StyledListItem >
          <StyledListItemText primary="Webiste Designing" />
          <ListItemIcon>
            <KeyboardArrowDownIcon onClick={() => setShowDropdown(!showDropdown)} />
          </ListItemIcon>
        </StyledListItem>
        {showDropdown && <AnotherComponent/>} 
        <Divider />
        <StyledListItem>
          <StyledListItemText primary="Contact Us" />
        </StyledListItem>
        <Divider />
        <StyledListItem>
          <StyledListItemText primary="Upskill" />
        </StyledListItem>
        <Divider />
        <StyledListItem>
          <StyledListItemText primary="Expert" />
        </StyledListItem>
        <Divider />
        <StyledListItem>
          <StyledListItemText primary="Blogs" />
        </StyledListItem>
        <Divider />
      </List>
    </div>
  );

  return (
    <StyledDrawer anchor="left" open={open} onClose={onClose}>
      {list()}
    </StyledDrawer>
  );
};

export default Sidebar;
