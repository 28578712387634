import React from "react";
import "./index.css"; // Import your CSS file
import { PopupWidget } from "react-calendly";

const CalendlyLink = "https://calendly.com/linkedstacksolutions";

const Popup = () => {
  return (
    <div className="popup-container">
      <div>
        <PopupWidget
          className="toggle-button"
          url={CalendlyLink}
          rootElement={document.getElementById("root")}
          text="Click here to schedule a meeting!"
          textColor="#ffffff"
          color="#00a2ff"
        />
      </div>
    </div>
  );
};

export default Popup;
