import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #fff5ee; // Soft color for the wedding theme
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  width: 80%;
  max-width: 500px;
`;

export const Title = styled.h2`
  color: #b76e79; // Soft color complementing the theme
  margin-bottom: 20px;
`;

export const Detail = styled.ul`
  list-style: none;
  padding: 0;
`;

export const DetailItem = styled.li`
  margin-bottom: 10px;
  color: #333; // Professional look
  font-size: 16px;

  strong {
    color: #444;
  }
`;

// Responsive adjustments
// @media (max-width: 768px) {
//   ${Container} {
//     width: 95%;
//     padding: 15px;
//   }

//   ${Title}, ${DetailItem} {
//     font-size: 14px;
//   }
// }
