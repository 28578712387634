import React from "react";
import FooterVersionZeroOne from "./footerVersionZeroOne/footerVersionZeroOne";
import FooterVersionZeroTwo from "../../components/footer/FooterVersionZeroTwo/index";
import FLAGS from "../../comman/flag";
const Footer = () => {
  return (
    <>
      {FLAGS?.FOOTER?.version01 && <FooterVersionZeroOne />}
      {FLAGS?.FOOTER?.version02 && <FooterVersionZeroTwo />}
    </>
  );
};

export default Footer;
