// RectComponent.js
import React from "react";
import { RectContainer, ContactDetails, Message } from "./contactDetailsStyle";

const ContactDetailsMain = () => {
  return (
    <RectContainer>
      <h2>Contact Details</h2>
      <ContactDetails>
        <p>
          <strong>Groom's Father:</strong> Mr. Rajesh Sharma
        </p>
        <p>
          <strong>Phone:</strong> +91 98765 43210
        </p>
        <p>
          <strong>Email:</strong> rajesh.sharma@example.com
        </p>
      </ContactDetails>
      <Message>
        We are delighted to share our son's profile with you. If you like the
        profile and wish to take things forward, please feel free to reach out
        to us. We look forward to connecting with you and welcoming you to our
        family with love and joy.
      </Message>
    </RectContainer>
  );
};

export default ContactDetailsMain;
