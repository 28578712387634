import React from "react";
import LocalCafeIcon from "@mui/icons-material/LocalCafe";
import BusinessIcon from "@mui/icons-material/Business";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CategoryIcon from "@mui/icons-material/Category";
import StorageIcon from "@mui/icons-material/Storage";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  Root,
  StyledList,
  StyledListItem,
  StyledListItemIcon,
  StaticIcon,
  CorporateIcon,
  FlashIcon,
  DynamicIcon,
  CatalogueIcon,
  CmsIcon,
  EcommerceIcon,
  StyledListItemText,
} from "./anotherComponentStyle";

const services = [
  {
    icon: <LocalCafeIcon />,
    primary: "STATIC WEBSITE",
    secondary: "Static Website Design in Noida",
    styleClass: StaticIcon,
  },
  {
    icon: <BusinessIcon />,
    primary: "CORPORATE WEBSITE",
    secondary: "All types of Corporate Website",
    styleClass: CorporateIcon,
  },
  {
    icon: <FlashOnIcon />,
    primary: "FLASH WEBSITE",
    secondary: "Flash Website in Noida",
    styleClass: FlashIcon,
  },
  {
    icon: <VisibilityIcon />,
    primary: "DYNAMIC WEBSITE",
    secondary: "All Types of Dynamic Website",
    styleClass: DynamicIcon,
  },
  {
    icon: <CategoryIcon />,
    primary: "PRODUCT CATALOGUE",
    secondary: "All Types of Product Catalogue",
    styleClass: CatalogueIcon,
  },
  {
    icon: <StorageIcon />,
    primary: "CMS WEBSITE",
    secondary: "All Types of CMS Website",
    styleClass: CmsIcon,
  },
  {
    icon: <ShoppingCartIcon />,
    primary: "ECOMMERCE WEBSITE",
    secondary: "Ecommerce Website Design in Noida",
    styleClass: EcommerceIcon,
  },
];

const AnotherComponent = () => {
  return (
    <Root>
      <StyledList>
        {services?.map((service, index) => (
          <StyledListItem key={index}>
            <StyledListItemIcon as={service?.styleClass}>
              {service?.icon}
            </StyledListItemIcon>
            <StyledListItemText
              primary={service?.primary}
              secondary={service?.secondary}
            />
          </StyledListItem>
        ))}
      </StyledList>
    </Root>
  );
};

export default AnotherComponent;
