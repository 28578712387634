import { styled } from '@mui/material/styles';
import { ListItem, ListItemIcon, List, Typography, Paper, MenuItem, Box,ListItemText } from '@mui/material';
import { blue, orange, lightBlue, purple, cyan, red } from '@mui/material/colors';

export const StyledPaper = styled(Paper)(({ theme }) => ({
  position: "absolute",
  top: 56,
  right: 0,
  margin: "2%",
  zIndex: 1,
  minWidth: 500,
  padding: "14px 20px 14px 10px",
}));
export const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  color: '#05264e',
  '& .MuiListItemText-primary': {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  '& .MuiListItemText-secondary': {
    fontSize: '12px',
    color: theme.palette.text.secondary,
  },
}))

export const HeadingTypography = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 500,
  textAlign: "justify",
  textTransform: "uppercase",
  marginBottom: theme.spacing(1),
}));

export const SubheadingTypography = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 500,
  textAlign: "justify",
  color: theme.palette.text.secondary,
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&:hover": {
    boxShadow: "0 4px 2px 0px",
    borderBottom: "1px solid #087990",
    background: "white",
    borderRadius: "5px solid ",
  },
}));

export const IconBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: "10px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const Root = styled('div')(({ theme }) => ({
  width: '250px',
  padding: '10px',
}));

export const StyledList = styled(List)({
  width: '100%',
});

export const StyledListItem = styled(ListItem)({
  display: 'flex',
  alignItems: 'center',
  color: '#05264e',
  padding: '10px 0',
  textTransform: 'uppercase',
  fontWeight: 500,
  fontSize: '12px',
});

export const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: '40px',
});

export const StaticIcon = styled(ListItemIcon)({
  color: blue[500],
});

export const CorporateIcon = styled(ListItemIcon)({
  color: orange[500],
});

export const FlashIcon = styled(ListItemIcon)({
  color: lightBlue[500],
});

export const DynamicIcon = styled(ListItemIcon)({
  color: purple[500],
});

export const CatalogueIcon = styled(ListItemIcon)({
  color: cyan[500],
});

export const CmsIcon = styled(ListItemIcon)({
  color: red[500],
});

export const EcommerceIcon = styled(ListItemIcon)({
  color: red[500],
});


