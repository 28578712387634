import React from "react";
import "./index.css"; // Importing the CSS file

const Footer = () => {
  return (
    <footer className="real-estate-footer">
      <div className="real-estate-container">
        <div className="real-estate-content">
          <p>© 2024 YourCompanyName. All rights reserved.</p>
          <p>Contact Us: contact@yourcompany.com</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
