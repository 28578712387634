const modules = [
  {
    module: "Module 1",
    topic: "Introduction to DevOps",
    subTopics: [
      "1.1 What is DevOps?",
      "1.2 DevOps principles",
      "1.3 The DevOps lifecycle",
      "1.4 Key DevOps practices",
      "1.5 Benefits of DevOps",
    ],
  },
  {
    module: "Module 2",
    topic: "Version Control with Git",
    subTopics: [
      "2.1 Introduction to Git",
      "2.2 Git Branching and Merging",
      "2.3 Git workflows",
      "2.4 Using GitHub",
      "2.5 Git best practices",
    ],
  },
  {
    module: "Module 3",
    topic: "Continuous Integration and Continuous Deployment (CI/CD)",
    subTopics: [
      "3.1 Introduction to CI/CD",
      "3.2 Setting up CI/CD pipelines",
      "3.3 Tools for CI/CD (e.g., Jenkins, CircleCI)",
      "3.4 Automated testing in pipelines",
      "3.5 Deployment strategies",
    ],
  },
  {
    module: "Module 4",
    topic: "Containerization with Docker",
    subTopics: [
      "4.1 Introduction to Docker",
      "4.2 Docker Images and Containers",
      "4.3 Docker Compose",
      "4.4 Container Orchestration with Docker Swarm",
      "4.5 Best Practices in Container Management",
    ],
  },
  {
    module: "Module 5",
    topic: "Kubernetes for Container Orchestration",
    subTopics: [
      "5.1 Introduction to Kubernetes",
      "5.2 Kubernetes Architecture",
      "5.3 Deploying Applications on Kubernetes",
      "5.4 Kubernetes Networking",
      "5.5 Managing State with Persistent Volumes",
    ],
  },
  {
    module: "Module 6",
    topic: "Infrastructure as Code (IaC)",
    subTopics: [
      "6.1 Understanding IaC",
      "6.2 Tools for IaC (e.g., Terraform, Ansible)",
      "6.3 Writing and managing infrastructure code",
      "6.4 Best practices in IaC",
      "6.5 IaC for cloud environments",
    ],
  },
  {
    module: "Module 7",
    topic: "Monitoring and Logging",
    subTopics: [
      "7.1 Importance of monitoring and logging",
      "7.2 Tools for monitoring (e.g., Prometheus, Nagios)",
      "7.3 Tools for logging (e.g., ELK Stack)",
      "7.4 Setting up alerts and dashboards",
      "7.5 Analyzing logs for troubleshooting",
    ],
  },
  {
    module: "Module 8",
    topic: "Cloud Computing and DevOps",
    subTopics: [
      "8.1 Introduction to Cloud Computing",
      "8.2 Cloud Service Models (IaaS, PaaS, SaaS)",
      "8.3 Cloud providers (AWS, Azure, GCP)",
      "8.4 DevOps in the cloud",
      "8.5 Managing cloud resources",
    ],
  },
  {
    module: "Module 9",
    topic: "Security in DevOps (DevSecOps)",
    subTopics: [
      "9.1 Understanding DevSecOps",
      "9.2 Integrating security in CI/CD pipeline",
      "9.3 Security automation tools",
      "9.4 Best practices for DevSecOps",
      "9.5 Vulnerability assessment and threat modeling",
    ],
  },
  {
    module: "Module 10",
    topic: "Microservices and DevOps",
    subTopics: [
      "10.1 Understanding Microservices",
      "10.2 Designing microservices architectures",
      "10.3 Deploying microservices with Docker and Kubernetes",
      "10.4 Challenges in microservices environments",
      "10.5 Best practices for managing microservices",
    ],
  },
  {
    module: "Module 11",
    topic: "Performance Tuning and Optimization",
    subTopics: [
      "11.1 Performance metrics and KPIs",
      "11.2 Performance testing tools",
      "11.3 Optimizing application and infrastructure performance",
      "11.4 Scaling applications and infrastructure",
      "11.5 Diagnosing and resolving performance issues",
    ],
  },
  {
    module: "Module 12",
    topic: "Project and Team Management in DevOps",
    subTopics: [
      "12.1 Agile and Scrum methodologies",
      "12.2 Role of a DevOps engineer in a team",
      "12.3 Collaboration and communication in DevOps",
      "12.4 Tools for project and team management (e.g., JIRA)",
      "12.5 Building a DevOps culture",
    ],
  },
];
export default modules;
