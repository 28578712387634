// EducationDetails.js
import React from "react";
import {
  EducationContainer,
  EducationList,
  EducationItem,
  ItemLabel,
  ItemText,
} from "./educationStyle";

const EducationDetails = ({ education }) => {
  return (
    <EducationContainer>
      <h2>Educational Details</h2>
      <EducationList>
        {education.map((edu, index) => (
          <EducationItem key={index}>
            <ItemLabel>Degree:</ItemLabel>
            <ItemText>{edu.degree}</ItemText>
            <br />
            <ItemLabel>College:</ItemLabel>
            <ItemText>{edu.college}</ItemText>
            <br />
            <ItemLabel>Passing Year:</ItemLabel>
            <ItemText>{edu.passingYear}</ItemText>
          </EducationItem>
        ))}
      </EducationList>
    </EducationContainer>
  );
};

export default EducationDetails;
