import React, { useState } from "react";
import "./index.css";
import ReactTraining from "../reactTraining";
import Devops from "../devops";

const technologies = ["React Training", "Devops Training"];

const CollapsibleBar = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible-bar">
      <div className="bar-title" onClick={toggleOpen}>
        <p className="title">{title}</p>
        {/* <span className="arrow">{isOpen ? "↓" : "→"}</span> */}
      </div>
      {isOpen && title == "React Training" && <ReactTraining />}
      {isOpen && title == "Devops Training" && <Devops />}
    </div>
  );
};

const TechnologyList = () => {
  return (
    <>
      <h2 id="upskill">Upskill</h2>
      <div className="tech-list-container">
        {technologies.map((tech, index) => (
          <CollapsibleBar title={tech} description={"Sample 123"} />
        ))}
      </div>
    </>
  );
};

export default TechnologyList;
