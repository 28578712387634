import React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import LocalCafeIcon from "@mui/icons-material/LocalCafe";
import FilterVintageIcon from "@mui/icons-material/FilterVintage";
import DraftsIcon from "@mui/icons-material/Drafts";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import { Grid } from "@mui/material";
import { StyledPaper, HeadingTypography, SubheadingTypography, StyledMenuItem ,StyledBox } from "./dropDownMenuStyle";
import color from "../../../src/comman/color";

const DropDownMenu = ({ open, onClose }) => {
  if (!open) return null;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <StyledPaper elevation={3}>
        <Grid container spacing={2} p={2} m={2}>
          <StyledMenuItem onClick={onClose}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <StyledBox bgcolor={color.BLUE}>
                  <LocalCafeIcon />
                </StyledBox>
              </Grid>
              <Grid item xs={9}>
                <HeadingTypography>Static Website</HeadingTypography>
                <SubheadingTypography>
                  Static Website Design in Noida
                </SubheadingTypography>
              </Grid>
            </Grid>
          </StyledMenuItem>

          <StyledMenuItem>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <StyledBox bgcolor={color.PEACH}>
                  <FilterVintageIcon />
                </StyledBox>
              </Grid>
              <Grid item xs={9}>
                <HeadingTypography>Corporate Website</HeadingTypography>
                <SubheadingTypography>
                  All types of Corporate Websites
                </SubheadingTypography>
              </Grid>
            </Grid>
          </StyledMenuItem>
        </Grid>

        <Grid container spacing={2} p={2} m={2}>
          <StyledMenuItem onClick={onClose}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <StyledBox bgcolor={color.PEACH}>
                  <DraftsIcon />
                </StyledBox>
              </Grid>
              <Grid item xs={9}>
                <HeadingTypography>Flash Website</HeadingTypography>
                <SubheadingTypography>
                  Flash Website Design in Noida
                </SubheadingTypography>
              </Grid>
            </Grid>
          </StyledMenuItem>

          <StyledMenuItem>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <StyledBox bgcolor={color.PURPLE}>
                  <VisibilityIcon />
                </StyledBox>
              </Grid>
              <Grid item xs={9}>
                <HeadingTypography>Dynamic Website</HeadingTypography>
                <SubheadingTypography>
                  All types of Dynamic Website
                </SubheadingTypography>
              </Grid>
            </Grid>
          </StyledMenuItem>
        </Grid>

        <Grid container spacing={2} p={2} m={2}>
          <StyledMenuItem onClick={onClose}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <StyledBox bgcolor={color.PURPLE}>
                  <ProductionQuantityLimitsIcon />
                </StyledBox>
              </Grid>
              <Grid item xs={9}>
                <HeadingTypography>PRODUCT CATALOGUE</HeadingTypography>
                <SubheadingTypography>
                  All types of Product Catalogue
                </SubheadingTypography>
              </Grid>
            </Grid>
          </StyledMenuItem>

          <StyledMenuItem>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <StyledBox bgcolor={color.BLUE}>
                  <BusinessCenterIcon />
                </StyledBox>
              </Grid>
              <Grid item xs={9}>
                <HeadingTypography>CMS Website</HeadingTypography>
                <SubheadingTypography>
                  All types of CMS Website
                </SubheadingTypography>
              </Grid>
            </Grid>
          </StyledMenuItem>
        </Grid>

        <Grid container spacing={2} p={2} m={2}>
          <StyledMenuItem onClick={onClose}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <StyledBox bgcolor={color.PURPLE}>
                  <ProductionQuantityLimitsIcon />
                </StyledBox>
              </Grid>
              <Grid item xs={9}>
                <HeadingTypography>Website Maintainance</HeadingTypography>
                <SubheadingTypography>
                  Website Maintainance in Noida
                </SubheadingTypography>
              </Grid>
            </Grid>
          </StyledMenuItem>

          <StyledMenuItem>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <StyledBox bgcolor={color.BLUE}>
                  <BusinessCenterIcon />
                </StyledBox>
              </Grid>
              <Grid item xs={9}>
                <HeadingTypography>Video Editing Service</HeadingTypography>
                <SubheadingTypography>
                  Video Editing Service in Noida
                </SubheadingTypography>
              </Grid>
            </Grid>
          </StyledMenuItem>
        </Grid>
      </StyledPaper>
    </ClickAwayListener>
  );
};

export default DropDownMenu;
