import styled from "styled-components";

export const ImageContainer = styled.div`
  width: 100%;
  height: 999px;
  // You can adjust this based on your requirement
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
  transition: background-image 0.5s ease-in-out; // Smooth transition between images

  @media (min-width: 768px) {
    height: 999px; // Larger display for larger devices
  }
`;
