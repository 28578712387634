import React from "react";
import "./index.css"; 
import LOGO_MAIN from "../../../assets/logoMain.png";
import CompanyIcon from "../../../assets/company.png";
import BlogsIcon from "../../../assets/faq.png";
import Experts from "../../../assets/businessman.png";
import UpskillIcon from "../../../assets/career-path.png";
import ServicesIcon from '../../../assets/ImageThree.png';
import {  useNavigate } from "react-router-dom";

const HeaderVersionTwo = () => {
  const navigate = useNavigate();

  const scrollToSection = (e) => {
    e.preventDefault(); 
    const targetId = e.currentTarget.getAttribute("href"); 
    const targetElement = document.querySelector(targetId); 

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" }); 
    }
  };

  return (
    <header className="main-header">
      <img
        onClick={scrollToSection}
        src={LOGO_MAIN}
        alt="Logo"
        className="logo"
        href="#header"
      />
      <nav className="navbar">
        <div className="nav-item">
          <img src={CompanyIcon} width={"30px"} height={"30px"} />
          <a
            href="#company"
            onClick={scrollToSection}
            style={{ paddingBottom: "2px" }}
          >
            Company
          </a>
        </div>
        <div className="nav-item">
          <a href="#services" onClick={scrollToSection}>
            <img src={ServicesIcon} width={"30px"} height={"30px"} />
            Services
          </a>
        </div>

        <div className="nav-item">
          <a href="#upskill" onClick={scrollToSection}>
            <img src={UpskillIcon} width={"30px"} height={"30px"} />
            Upskill
          </a>
        </div>
        <div className="nav-item">
          <a href="#team" onClick={scrollToSection}>
            <img src={Experts} width={"30px"} height={"30px"} />
            Our Experts
          </a>
        </div>
        <div className="nav-item">
          <img src={BlogsIcon} width={"30px"} height={"30px"} />
          <a
            onClick={() => {
              {

              }
            }}
          >
            Blogs
          </a>
        </div>
      </nav>
    </header>
  );
};

export default HeaderVersionTwo;
