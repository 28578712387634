import React, { useState, useEffect } from "react";
import "./index.css"; // Importing the CSS file for styling
import BannerOne from "../../assets/bannerOne.png";
import BannerTwo from "../../assets/bannerTwo.png";
import BannerThree from "../../assets/bannerThree.png";
import BannerFour from "../../assets/bannerFour.png";
import BannerFive from "../../assets/bannerFive.png";

const BannerSlider = () => {
  const imageUrls = [
    BannerOne,
    BannerTwo,
    BannerThree,
    BannerFour,
    BannerFive,
    // Add more image URLs as needed
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000); // Change image every 2 seconds

    return () => clearInterval(intervalId);
  }, [imageUrls.length]);

  return (
    <div className="banner-slider">
      <img src={imageUrls[currentImageIndex]} alt="Banner" />
    </div>
  );
};

export default BannerSlider;
