import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
`;

export const Card = styled.div`
  flex: 0 1 calc(20% - 24px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  margin: 10px;
  padding: 20px;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 1024px) {
    flex: 0 1 calc(50% - 20px);
  }

  @media (max-width: 768px) {
    flex: 0 1 90%;
  }
`;

export const Title = styled.h2`
  color: #b07219;
  font-family: "Arial", sans-serif;
  margin-bottom: 10px;
`;

export const Info = styled.p`
  font-size: 14px;
  color: #666;
  font-weight: 700;
`;
