import React, { useState } from "react";
import cardBackgroundImage from "../../../src/assets/imageSevenThree.png";
import "./index.css";

const ServiceCard = ({ title, shortDescription, fullDescription }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const cardStyle = {
    width: "300px",
    height: "200px",
    backgroundImage: `url(${cardBackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff", // Change text color for visibility if needed
  };

  return (
    <div
      className={`service-card ${isFlipped ? "flipped" : ""}`}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <div className="card-face front" style={cardStyle}>
        <h2 style={{ color: "gray" }}>{title}</h2>
        <p style={{ color: "black" }}>{shortDescription}</p>
        <p className="readMore"> Read More...</p>
      </div>
      <div className="card-face back">
        <h2 className="heading">More About {title}</h2>
        <p>{fullDescription}</p>
      </div>
    </div>
  );
};

const Company = () => {
  const services = [
    {
      title: "Web Site Development",
      shortDescription:
        "Customized web development services for your business needs",
      fullDescription:
        "Our web development team specializes in creating bespoke websites tailored to your business objectives. From e-commerce platforms to informational sites, we use the latest technologies to build responsive, user-friendly, and efficient websites.",
    },
    {
      title: "Digital Marketing",
      shortDescription:
        "Elevate your brand with our digital marketing expertise",
      fullDescription:
        "Our digital marketing services are designed to increase your online presence and engage your target audience effectively. From SEO to social media marketing, we create customized strategies that align with your business goals and drive growth.",
    },
    {
      title: "Video Designing Editing",
      shortDescription:
        "Professional video editing to bring your vision to life",
      fullDescription:
        "Our video editing service offers high-quality, creative editing for all types of video content. Whether it's for commercial, educational, or personal projects, we use the latest tools and techniques to deliver crisp, engaging, and visually appealing videos.",
    },

    {
      title: "Graphic & Logo Design, Card Creation",
      shortDescription: "Tailored designs for branding, logos, and cards",
      fullDescription:
        "Our team provides expert graphic design for logos and custom cards, emphasizing unique, impactful visuals tailored to your brand's needs and messaging.",
    },

    {
      title: "Professional Resume Crafting",
      shortDescription: "Tailored resume preparation to land your dream job",
      fullDescription:
        "Stand out in the job market with our professional resume preparation service. Our experts help you showcase your skills and experiences effectively, crafting a resume that catches the eye of recruiters in your desired industry.",
    },
    {
      title: "Brand Positioning",
      shortDescription:
        "Strategically position your brand in the competitive market",
      fullDescription:
        "Our brand positioning services focus on defining, developing, and communicating your brand's unique value. We help you stand out in the marketplace by crafting a compelling brand narrative that resonates with your target audience and fosters long-term loyalty.",
    },
    {
      title: "Expert React Training",
      shortDescription: "Master React with our comprehensive training program",
      fullDescription:
        "Dive deep into React development with our expert-led training. Covering essential topics like hooks, state management, and component lifecycle, this course is designed to elevate your skills to create dynamic, efficient web applications.",
    },
    {
      title: "Dedicated React Support",
      shortDescription: "Personalized React support for your development needs",
      fullDescription:
        "Get the React assistance you need with our dedicated support service. Whether it's debugging, code review, or architecture advice, our experts are here to help you overcome challenges and enhance your React projects.",
    },
    {
      title: "HTML, CSS, & JS Mastery",
      shortDescription: "Comprehensive training in web fundamentals",
      fullDescription:
        "Build a solid foundation in web development with our extensive course on HTML, CSS, and JavaScript. From basic syntax to advanced concepts, this training prepares you to create stunning, responsive websites with confidence.",
    },

    {
      title: "Job Portal Management",
      shortDescription: "Efficient management of your job application process",
      fullDescription:
        "Streamline your job search with our job portal management service. We take care of updating and managing your profiles on various job portals, ensuring your applications are organized and your chances of landing the perfect job are maximized.",
    },
    {
      title: "Full Stack Web Development Course",
      shortDescription: "Become a versatile full-stack developer",
      fullDescription:
        "This comprehensive course covers both front-end and back-end development. Learn to build complete web applications using technologies like Node.js, Express, MongoDB, alongside React, HTML, CSS, and JavaScript.",
    },
    {
      title: "Code Review and Optimization",
      shortDescription: "Enhance your code quality and performance",
      fullDescription:
        "Our code review service helps you improve and optimize your codebase. Get expert feedback on code efficiency, structure, and best practices to ensure your projects are scalable and maintainable.",
    },
    {
      title: "Interview Preparation for Developers",
      shortDescription: "Ace your tech interviews with confidence",
      fullDescription:
        "Prepare for technical interviews with our targeted training. We cover common interview questions, problem-solving strategies, and coding challenges to help you perform confidently and land your ideal developer role.",
    },
    {
      title: "JavaScript Advanced Concepts Training",
      shortDescription: "Deep dive into advanced JavaScript",
      fullDescription:
        "Elevate your JavaScript skills with our advanced training. Explore complex concepts like asynchronous programming, closures, prototypes, and new ES6+ features to write more efficient and powerful JavaScript code.",
    },

    {
      title: "DevOps Consultation and Training",
      shortDescription:
        "Streamline your operations with our expert DevOps consultation",
      fullDescription:
        "Enhance your development and operations workflow with our comprehensive DevOps consulting and training services. We provide insights into best practices, automation strategies, and continuous integration and deployment to optimize your software development lifecycle.",
    },
  ];

  return (
    <div className="main-company-container" id="services">
      <h1>Our Services</h1>
      <div className="services-grid">
        {services.map((service, index) => (
          <div>
            <ServiceCard key={index} {...service} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Company;
