const FLAGS = {
  HEADER: {
    version01: false,
    version02: true,
  },
  FOOTER: {
    version01: false,
    version02: true,
  },
  MAINCONTAINER: {
    version1: true,
    version2: false,
  },
  CONTACTUS: {
    version1: true,
    version2: false,
  },
  INFO: {
    version1: true,
    version2: false,
  },
  NAVIGATION: {
    version1: true,
    version2: false,
  },
  SIDEBAR: {
    version1: true,
    version2: false,
  },
  ABOUTUS: {
    version1: true,
    version2: false,
  },
  SERVICES: {
    version1: true,
    version2: false,
  },
  TESTIMONIALS: {
    version1: true,
    version2: false,
  },
};

export default FLAGS;
