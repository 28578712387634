import React from "react";
import "./index.css";

const Header = () => {
  const scrollToSection = (event) => {
    event.preventDefault(); // Prevent default anchor click behavior
    const sectionId = event.target.getAttribute("href").slice(1); // Get the section id (removing the '#' symbol)
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" }); // Smooth scroll to the section
    }
  };

  return (
    <header className="header">
      <div className="logo">YourLogo</div>
      <nav className="navigation">
        <a href="#home" onClick={scrollToSection}>
          Home
        </a>

        <a href="#buy" onClick={scrollToSection}>
          Buy Properties
        </a>
        <a href="#about" onClick={scrollToSection}>
          About Us
        </a>
        <a href="#contact" onClick={scrollToSection}>
          Contact Us
        </a>
      </nav>
      {/* <div className="search">
        <input type="text" placeholder="Search Properties" />
        <button>Search</button>
      </div> */}
    </header>
  );
};

export default Header;
