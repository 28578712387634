// Rect.js
import React from "react";
import { Avatar, Name, Divider } from "./headerStyle";
import Adarsh from "../../../assets/adarsh.png";

const Header = ({ name }) => {
  return (
    <div style={{ alignContent: "flex-start", display: "flex" }}>
      <Avatar src={Adarsh} alt="avatar" />
      <div style={{ marginTop: "8%" }}>
        <Name>{name}</Name>
        {/* <Divider /> */}
      </div>
    </div>
  );
};

export default Header;
