import React, { useState } from "react";
import CardImage from "../../../assets/imageSevenThree.png";
import "./index.css";
import Keys from "../../../assets/key.png";
import TenantVerification from "../../../assets/property.png";
import Paint from "../../../assets/roller-paint.png";
import Hammer from "../../../assets/hammer.png";
import Broom from "../../../assets/broom.png";
// import Keys from "../../assets/key.png";

const ServiceCard = ({
  name,
  description,
  fullDescription = "",
  imageName,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <div
      className={`service-card ${isFlipped ? "flipped" : ""}`}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <div
        className="card-face front"
        style={{
          width: " 300px",
          height: " 200px",
          backgroundImage: `url(${CardImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <img src={imageName} height={"40px"} width={"40px"}></img>
        <h2>{name}</h2>
      </div>
      <div
        className="card-face back"
        style={{
          width: " 300px",
          height: " 200px",
          backgroundImage: `url(${CardImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2>More About {name}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};

const Company = () => {
  const services = [
    {
      title: "Expert React Training",
      shortDescription: "Master React with our comprehensive training program",
      fullDescription:
        "Dive deep into React development with our expert-led training. Covering essential topics like hooks, state management, and component lifecycle, this course is designed to elevate your skills to create dynamic, efficient web applications.",
    },
    {
      title: "Dedicated React Support",
      shortDescription: "Personalized React support for your development needs",
      fullDescription:
        "Get the React assistance you need with our dedicated support service. Whether it's debugging, code review, or architecture advice, our experts are here to help you overcome challenges and enhance your React projects.",
    },
    {
      title: "HTML, CSS, & JS Mastery",
      shortDescription: "Comprehensive training in web fundamentals",
      fullDescription:
        "Build a solid foundation in web development with our extensive course on HTML, CSS, and JavaScript. From basic syntax to advanced concepts, this training prepares you to create stunning, responsive websites with confidence.",
    },
    {
      title: "Professional Resume Crafting",
      shortDescription: "Tailored resume preparation to land your dream job",
      fullDescription:
        "Stand out in the job market with our professional resume preparation service. Our experts help you showcase your skills and experiences effectively, crafting a resume that catches the eye of recruiters in your desired industry.",
    },
    {
      title: "Job Portal Management",
      shortDescription: "Efficient management of your job application process",
      fullDescription:
        "Streamline your job search with our job portal management service. We take care of updating and managing your profiles on various job portals, ensuring your applications are organized and your chances of landing the perfect job are maximized.",
    },
    {
      title: "Full Stack Web Development Course",
      shortDescription: "Become a versatile full-stack developer",
      fullDescription:
        "This comprehensive course covers both front-end and back-end development. Learn to build complete web applications using technologies like Node.js, Express, MongoDB, alongside React, HTML, CSS, and JavaScript.",
    },
    {
      title: "Code Review and Optimization",
      shortDescription: "Enhance your code quality and performance",
      fullDescription:
        "Our code review service helps you improve and optimize your codebase. Get expert feedback on code efficiency, structure, and best practices to ensure your projects are scalable and maintainable.",
    },
    {
      title: "Interview Preparation for Developers",
      shortDescription: "Ace your tech interviews with confidence",
      fullDescription:
        "Prepare for technical interviews with our targeted training. We cover common interview questions, problem-solving strategies, and coding challenges to help you perform confidently and land your ideal developer role.",
    },
    {
      title: "JavaScript Advanced Concepts Training",
      shortDescription: "Deep dive into advanced JavaScript",
      fullDescription:
        "Elevate your JavaScript skills with our advanced training. Explore complex concepts like asynchronous programming, closures, prototypes, and new ES6+ features to write more efficient and powerful JavaScript code.",
    },
    {
      title: "Project Management for Tech Projects",
      shortDescription: "Efficiently manage your tech projects",
      fullDescription:
        "Learn the art of project management specifically tailored for technology projects. This service covers agile methodologies, team collaboration, time management, and resource allocation to keep your projects on track and successful.",
    },
  ];

  const realEstateServices = [
    {
      imageName: Keys,
      name: "Real Estate Buying and Selling",
      description:
        "Expert property transaction services with comprehensive market analysis.",
    },
    {
      imageName: Keys,
      name: "Rent Agreement Facilitation",
      description:
        "Legal and efficient rent agreement drafting and registration.",
    },
    {
      imageName: TenantVerification,
      name: "Tenant Verification",
      description: "Detailed tenant screening for reliable property occupancy.",
    },
    {
      imageName: Broom,
      name: "Home Cleaning Services",
      description:
        "Superior property cleaning for optimal maintenance and hygiene.",
    },
    {
      imageName: Paint,
      name: "Professional Painting Services",
      description: "High-quality, eco-friendly interior and exterior painting.",
    },
    {
      imageName: Hammer,
      name: "Interior Design and Decoration",
      description: "Customized interior design and decoration solutions.",
    },
    {
      imageName: Hammer,
      name: "Home & Office Furniture Repairs",
      description:
        "Professional furniture repair and restoration for enhanced aesthetics.",
    },
  ];

  return (
    <div className="company-container" id="services">
      <h1>Service at Call</h1>
      <div className="services-grid">
        {realEstateServices.map((service, index) => (
          <ServiceCard key={index} {...service} />
        ))}
      </div>
    </div>
  );
};

export default Company;
