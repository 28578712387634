import React, { useState } from "react";
import "./index.css"; // Importing the CSS file for styling
import modules from "./data";

const ModuleList = () => {
  const [selectedModule, setSelectedModule] = useState(null);

  const toggleModule = (module) => {
    setSelectedModule(selectedModule === module ? null : module);
  };

  return (
    <div className="module-list">
      {modules.map((module, index) => (
        <div key={index}>
          <div
            className="module-item"
            onClick={() => toggleModule(module.module)}
          >
            {module.module}: {module.topic}
          </div>
          {selectedModule === module.module && (
            <SubTopicList subTopics={module.subTopics} />
          )}
        </div>
      ))}
    </div>
  );
};

const SubTopicList = ({ subTopics }) => (
  <div className="subtopic-list">
    {subTopics.map((subTopic, index) => (
      <div key={index} className="subtopic-item">
        {subTopic}
      </div>
    ))}
  </div>
);

export default ModuleList;
