import { styled } from "@mui/system";
import { Box, Typography, Link } from "@mui/material";
import COLORS from "../../../comman/color";

export const FooterContainer = styled(Box)({
  background: "linear-gradient(to bottom, #0b219f, #4d67e5, #142d79)",
  color: "white",
  padding: "32px",
  width: "100%",
  marginTop: "32px",
  textAlign: "left",
  "&.center-text": {
    textAlign: "center",
  },
});

export const FooterLogoImage = styled("img")({
  margin: "5%",
});

export const FooterGrid = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
});

export const FooterItem = styled(Box)({
  flex: 1,
  minWidth: "200px",
  margin: "20px",
  marginBottom: "16px",
  "& h6": {
    marginBottom: "16px",
  },
  "& a": {
    color: "white",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

export const LinkedStackText = styled(Typography)({
  fontSize: "12px",
  textAlign: "justify",
  color: COLORS.TYPOGRAPHY.H1,
  fontFamily: '"Archivo", Sans-serif',
  marginRight: "20%",
});

export const QuickLink = styled(Link)({
  fontSize: "13px",
  padding: "1px",
  marginLeft: "60px",
  display: "block",
  margin: "5px",
});

export const UsefulLink = styled(Link)({
  fontSize: "13px",
  padding: "1px",
  display: "block",
  margin: "5px",
});
