// EducationStyles.js
import styled from "styled-components";

export const EducationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  padding: 20px;
  border: 2px solid #ffd700; // Golden color for the theme
  border-radius: 10px;
  background-color: #fff5ee; // Soft Seashell background for a soft look
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const EducationList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const EducationItem = styled.li`
  margin: 10px 0;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #2f4f4f; // Dark Slate Gray for readability

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const ItemLabel = styled.span`
  font-weight: bold;
`;

export const ItemText = styled.span`
  margin-left: 5px;
`;
