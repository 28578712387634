import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import color from "../../../comman/color";

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: color.WHITE,
  color: color.BLACK,
  boxShadow: 'none',
  borderBottom: '1px solid #ccc',
}));
export const LoginButton= styled(Button)(({ theme }) => ({
backgroundL:'blue',
border:'2px solid red'
}));


export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const Logo = styled('img')(({ theme }) => ({
  height: '100px',
  width: '120px',
  [theme.breakpoints.down('sm')]: {
    height: '30px',
    width: 'auto',
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  [theme.breakpoints.down('sm')]: {
    flexGrow: 0,
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: '10px 20px',
  margin: '5px 0',
}));

export const MenuButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  padding: '20px 18px',
  margin: '5px 25px',
  fontWeight: '600',
  letterSpacing: '0.2px',
  textTransform: 'capitalize',
  fontSize: '15px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    display: 'none', 
  },
  '&:hover': {
    backgroundColor: color.WHITE,
    color: color.HOVERTEXT,
  },
}));

export const MenuIconButton = styled(IconButton)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

export const DropdownMenuWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover > .MuiSvgIcon-root': {
    color: '#008aff',
  },
  '& > .MuiSvgIcon-root': {
    marginLeft: theme.spacing(1),
  },
}));

export const MenuContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center', 
}));
