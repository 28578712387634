import styled from "styled-components";

export const StyledSidebar = styled.div`
  width: ${(props) => (props.isOpen ? "300px" : "100px")};
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  height: 100%;
  transition: width 0.3s ease-in-out;
  box-shadow: 5px 0 20px rgba(0, 0, 0, 0.4);
  position: relative;
  max-height: 100vh;
  overflow-y: auto;
  color: white;
  font-size: 18px;
`;

export const SidebarList = styled.ul`
  list-style-type: none;
  padding: 10px 0;
  margin: 0;
  margin-top: 60px; /* Added top margin to create space below the toggle button */
`;

export const SidebarItem = styled.li`
  padding: ${(props) => (props.isOpen ? "15px 20px" : " 10px 10px")};
  padding: 15px 20px;
  // margin: 5px 10px;
  margin: ${(props) => (props.isOpen ? "5px 10px" : "5px 10px")};
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  font-size: 16px;
`;

export const ToggleButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 10px 15px;
  border: none;
  background-color: #ff7676;
  color: white;
  border-radius: 8px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  font-size: 14px;
`;
