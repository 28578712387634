// StyledRect.js
import styled from "styled-components";

export const RectContainer = styled.div`
  background-color: #f9f0ea; /* Soft background color */
  color: #6d4c41; /* Professional font color */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px; /* Ensures the component is not too wide on larger screens */
  margin: auto; /* Centers the component */
  font-family: "Arial", sans-serif;

  @media (max-width: 768px) {
    padding: 15px;
    font-size: 14px; /* Adjust font size for smaller screens */
  }
`;

export const ContactDetails = styled.div`
  margin-top: 20px;
`;

export const Message = styled.p`
  margin-top: 20px;
  font-style: italic;
`;
