import Home from "./home";
import Header from "./header";
import Company from "./company";
import Contact from "./contactus";
import Footer from "./footer";
import LeaseOrSell from "./leaseorSell";
import Social from "./social";

function RealEstate() {
  return (
    <div style={{ background: "white" }}>
      <Header />
      {/* <Home /> */}

      <Company />
      <LeaseOrSell />
      <Contact />
      <Social />
      <Footer />
    </div>
  );
}

export default RealEstate;
