import React from 'react';
import HeaderVersionTwo from './headerVersionTwo';
import HeaderVersionOne from './headerVersionOne/headerVersionOne';
import FLAGS from '../../comman/flag';

const Header = () => {
  return (
   <>
     {FLAGS?.HEADER?.version01 && <HeaderVersionOne/>}
     {FLAGS?.HEADER?.version02 && <HeaderVersionTwo />}
   </>
  )
}

export default Header
