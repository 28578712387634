import React, { useState } from "react";
import "./index.css"; // Importing the CSS file
import Whatsapp from "../../../assets/whatsapp.png";

const ContactUs = () => {
  const [isOpen, setIsOpen] = useState(true);
  const phoneNumber = "+91 7015309925"; // Replace with your phone number
  const message = encodeURIComponent("Help me with your services");

  const handleOpenGoogleForm = () => {
    // setIsOpen(!isOpen);
    window.open("https://forms.gle/ttMuANxfBV1XDPPs6", "_balnk");
  };

  return (
    <div className="real-estate-contact-container" id="contact">
      {setIsOpen && (
        <>
          {/* <h2> New Form can come here ....</h2> */}
          {/* <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSeBSlt1TCWGF7PSih30v2rh3-XYMp0YFVqTcbVO0duQDO61XQ/viewform?embedded=true"
            width="500"
            height="500"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe> */}
        </>
      )}
      <h1>Contact Us</h1>
      <p>For inquiries or assistance, please reach out to us:</p>
      <ul>
        <li>
          {/* <a
            href="YOUR_GOOGLE_FORM_LINK"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleOpenGoogleForm()}
          >
            Fill out our contact form
          </a> */}
          <p
            style={{
              cursor: "pointer",
              fontSize: "24px",
              fontWweight: "bold",
              color: "purple",
            }}
            onClick={() => handleOpenGoogleForm()}
          >
            Fill out our contact form
          </p>
        </li>
        <li>
          Email:{" "}
          <a href="mailto:your-email@example.com">your-email@example.com</a>
        </li>
        <li>
          Phone: <a href="tel:+911234567890">+91 7015309925</a>
        </li>
      </ul>
      <a
        href={`https://wa.me/${phoneNumber}?text=${message}`}
        className="real-estate-whatsapp-icon"
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* <img src={Whatsapp} alt="WhatsApp" /> */}
      </a>
    </div>
  );
};

export default ContactUs;
