// PersonaDetailsStyled.js
import styled from "styled-components";

export const Container = styled.div`
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  max-width: 100%;
  margin: 20px auto;
  background: #f9f9f9;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-left: 4px solid #0077b5;
`;

export const SectionTitle = styled.h2`
  color: #0056b3;
  margin-bottom: 15px;
  padding-bottom: 5px;
  font-size: 24px;
  border-bottom: 2px solid #eee;
`;

export const DetailsList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const Detail = styled.li`
  margin-bottom: 10px;
  line-height: 1.6;
  color: #333;
`;

export const Highlight = styled.span`
  font-weight: 600;
  color: #0077b5;
`;

export const Link = styled.a`
  color: #0077b5;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;

export const Icon = styled.i`
  margin-right: 8px;
  color: #0077b5;
`;

// Assuming you're using a library like Font Awesome for icons
// You can install it using npm install @fortawesome/react-fontawesome
// and import specific icons as needed
