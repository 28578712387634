// GarvitAnandProfile.js
import React from "react";

import {
  Container,
  SectionTitle,
  DetailsList,
  Detail,
  Highlight,
  Link,
  Icon,
} from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faGraduationCap,
  faBriefcase,
  faHome,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import EducationLadder from "./componenets/education";
import PersonalSection from "./componenets/personalInformation";
import Header from "./componenets/header";
import FamilyDetails from "./componenets/familDetails";
import RotatingImageDisplay from "./componenets/images";
import ContactDetails from "./componenets/contactDetails";
const GarvitAnandProfile = () => {
  const educationData = [
    {
      degree: "B.Tech in Computer Science",
      college: "Indian Institute of Technology, Delhi",
      passingYear: "2020",
    },
    {
      degree: "M.Tech in Software Engineering",
      college: "Indian Institute of Technology, Kanpur",
      passingYear: "2022",
    },
  ];
  return (
    <Container>
      <Header name={"Garvit Anand"} />
      <PersonalSection
        details={[
          {
            name: "Garvit Anand",
            age: "28",
            height: "5'9\"",
            "Date of Birth": "14 July 1995",
            "Time of Birth": "08:35 PM",
            "Place of Birth": "Agra",
            horoscope: "Capricorn",
            caste: "Punjabi Khatri",
          },
        ]}
      />
      <FamilyDetails
        family={{
          fatherName: "Ravi Anand",
          fatherOccupation: "Retired",
          motherName: "Rekha",
          motherBusiness: "Homemaker",
          elderBrotherName: "Nikhil Anand",
          elderBrotherWifeName: "Neha",
          elderBrotherOccupation: "IT Manager, Gurgaon",
        }}
      />
      <SectionTitle>
        <FontAwesomeIcon icon={faUser} /> Profile Overview
      </SectionTitle>
      <DetailsList>
        <Detail>
          <Icon>
            <FontAwesomeIcon icon={faHeart} />
          </Icon>
          Name: <Highlight>Garvit Anand</Highlight>
        </Detail>
        <Detail>
          <Icon>
            <FontAwesomeIcon icon={faHome} />
          </Icon>
          Currently Living In:{" "}
          <Highlight>Gurgaon (We own 3 flats in Gurgaon)</Highlight>
        </Detail>
        <Detail>
          <Icon>
            <FontAwesomeIcon icon={faUser} />
          </Icon>
          Age: <Highlight>28 Years</Highlight> | Height:{" "}
          <Highlight>5'8"</Highlight> | Caste:{" "}
          <Highlight>Punjabi Khatri</Highlight>
        </Detail>
        <Detail>
          <Icon>
            <FontAwesomeIcon icon={faBriefcase} />
          </Icon>
          Designation: <Highlight>Senior Software Engineer</Highlight> at{" "}
          <Highlight>Nium India Pvt Ltd</Highlight>
        </Detail>
      </DetailsList>

      <SectionTitle>
        <FontAwesomeIcon icon={faGraduationCap} /> Education & Career
      </SectionTitle>
      <DetailsList>
        <Detail>
          <Icon>
            <FontAwesomeIcon icon={faGraduationCap} />
          </Icon>
          B.Tech (HONS) in Computer Science & Engineering | PGDM - IMT Ghaziabad
          | Pursuing MBA in Leadership - Deakins Business School, Australia
        </Detail>
        <Detail>
          <Icon>
            <FontAwesomeIcon icon={faBriefcase} />
          </Icon>
          Also runs IT Consulting Business:{" "}
          <Link href="http://www.linkedstacksolutions.com/">
            linkedstacksolutions.com
          </Link>
        </Detail>
      </DetailsList>

      <SectionTitle>Family Background</SectionTitle>
      <DetailsList>
        <Detail>
          Father: <Highlight>Retired</Highlight>
        </Detail>
        <Detail>
          Mother: <Highlight>Homemaker</Highlight>
        </Detail>
        <Detail>
          Elder brother:{" "}
          <Highlight>IT manager in an MNC company, in Gurgaon</Highlight>
        </Detail>
        <Detail>
          Sister In Law:{" "}
          <Highlight>Digital Marketing & Real Estate Consultant</Highlight>
        </Detail>
      </DetailsList>
      <EducationLadder education={educationData} />
      <RotatingImageDisplay />
      <ContactDetails />
    </Container>
  );
};

export default GarvitAnandProfile;
