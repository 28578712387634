const COLORS = {
  PRIMARY: "#1976d2",        // Blue
  SECONDARY: "#9c27b0",      // Purple
  ERROR: "#f44336",          // Red
  WARNING: "#ff9800",        // Orange
  INFO: "#2196f3",           // Light Blue
  SUCCESS: "#4caf50",        // Green
  BACKGROUND: "#f5f5f5",     // Grey
  TEXT_PRIMARY: "#212121",   // Dark Grey
  TEXT_SECONDARY: "#757575", // Medium Grey
  WHITE: "#ffffff",          // White
  BLACK: "#000000",          // Black
  HOVERTEXT:"#008aff",        //blue on hover
  BLUE:"#e0f7fa",
    PEACH:"#ffe0b2",
    PURPLE:"#d1c4e9",

  

  // Typography Colors
  TYPOGRAPHY: {
    H1: "#fff",              // white
    H2: "#212121",           // Dark Grey
    H3: "#212121",           // Dark Grey
    H4: "#212121",           // Dark Grey
    H5: "#212121",           // Dark Grey
    H6: "#212121",           // Dark Grey
    BODY1: "#757575",        // Medium Grey
    BODY2: "#757575",        // Medium Grey
  },

  // Button Colors
  BUTTON: {
    PRIMARY: {
      BACKGROUND: "#1976d2", // Blue
      TEXT: "#ffffff",       // White
    },
    SECONDARY: {
      BACKGROUND: "#9c27b0", // Purple
      TEXT: "#ffffff",       // White
    },
  },
};

export default COLORS;