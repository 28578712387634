import React, { useState } from "react";
import axios from "axios";

const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    // https://script.google.com/macros/s/AKfycbzze3-1Uawj1j8vYuQ5Rv_OO8qhzqRGcZZJw2feBUP5ajnb68Qz2rgrTgOIHchpmuqE/exec
    try {
      const response = await axios.post(
        `https://script.google.com/macros/s/AKfycbxAGnTWO3WYtbfO7OdzngoDWg9w42hyq_Pk41ScrP7oK1MbU7xhKaLjW7IRGpcTJ872/exec`, // Replace with your deployment URL
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Data stored in Google Sheets:", response.data);
    } catch (error) {
      console.error("Error storing data:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <br></br>
      <br></br>

      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <br></br>
      <br></br>

      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />

      <br></br>
      <br></br>

      <button type="submit">Login</button>
    </form>
  );
};

export default LoginForm;
