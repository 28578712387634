import React from "react";
import "./index.css"; // Import the CSS file for styling

// Sample array of objects for card data
const cardData = [
  {
    title: "KY Hazard Application",
    description: "Heavy Reconstruction Engine",
    linkText: "Explore our case studies",
    background: "blue-pattern.svg", // Replace with your actual background image
    icon: "hazard-icon.svg", // Replace with your actual icon
  },
  {
    title: "Work Permit Digitalisation",
    description: "Permit Digitization",
    linkText: "Explore our safety record",
    background: "green-pattern.svg", // Replace with your actual background image
    icon: "permit-icon.svg", // Replace with your actual icon
  },
  // ... add more cards as needed
];

function CardComponent() {
  return (
    <div className="card-container-deva">
      {cardData.map((card, index) => (
        <div
          className="cardNew"
          key={index}
          style={{ backgroundImage: `url(${card.background})` }}
        >
          <div className="text-content">
            <h2>{card.title}</h2>
            <p>{card.description}</p>
            <a href="#">{card.linkText}</a>
          </div>
          <div
            className="icon"
            style={{ backgroundImage: `url(${card.icon})` }}
          ></div>
        </div>
      ))}
    </div>
  );
}

export default CardComponent;
