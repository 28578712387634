import React from "react";
import "./index.css";
import SampleTestImage from "../../../src/assets/ImageNineNine.png";

const MyWorkShowcase = () => {
  const works = [
    {
      title: "Restaurant",
      image: SampleTestImage,
      url: "https://65ab41d40c77514f2f244988--shimmering-twilight-73a18c.netlify.app/",
    },
  ];
  return (
    <div className="showcase-container">
      <h2>My Work</h2>
      <div className="work-items">
        {works.map((work, index) => (
          <a
            key={index}
            href={work.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={work.image} alt={work.title} className="work-image" />
          </a>
        ))}
      </div>
    </div>
  );
};

export default MyWorkShowcase;
