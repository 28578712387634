import React from "react";
import "./index.css";

const Contact = () => {
  const openGoogleForm = () => {
    window.open("https://forms.gle/puahtUijKfGaCZvX8", "_blank"); // Replace with your Google Form link
  };

  return (
    <div className="contact-container" id="contactus">
      <div className="contact-card">
        <p className="contact-info">📞+91 7015309925</p>
        <p className="contact-info">
          📧{" "}
          <a href="mailto:gary@gmail.com">
            customersupport@linkedstacksolutions.com
          </a>
        </p>
        <button className="contact-button" onClick={openGoogleForm}>
          Raise a enquiry
        </button>
      </div>
    </div>
  );
};

export default Contact;
